body {
  font-family: "Amatic SC", cursive;
  background-size: cover;
  color: #fff;
  font-size: 16px;
  background-color: #000;
}

.bg {
  background: url("./assets/bg.jpg");
  filter: blur(10px);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

section {
  display: none;
  height: 100vh;
  justify-content: center;
  align-items: center;
  position: relative;
  animation: fade-in 1s;
}

h1 {
  font-size: 3rem;
}

p {
  font-size: 2rem;
}

a,
a:visited {
  color: #fff;
}

input {
  font-size: 1rem;
  text-align: center;
  padding: 1rem;
  border: none;
  border-radius: 5px;
  font-family: Helvetica, Arial, sans-serif;
  margin-right: 20px;
}

.content {
  padding: 2rem;
  text-align: center;
  max-width: 800px;
}

@keyframes pulse {
  from {
    opacity: 0.6;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.pulse-anim {
  animation: pulse 1s infinite both;
}
